import React, { useContext, useState, useEffect, useRef } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName } from "../helpers";
import {
  identity, transferDeSoToken,
  sendMessage, bs58PublicKeyToCompressedBytes,
  getUsersStateless, configure
} from "deso-protocol";
import logo from "../assets/desoguardlogo.svg";
import BigNumber from 'bignumber.js';
import userPrefsStore from 'context/userPrefsStore';
import { DollarSign, List, Shield, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import { Progress } from "components/ui/progress"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog"
import { Textarea } from "components/ui/textarea"
import PercentagePieChart from "components/graph"
import CCTransactionLineChart from 'components/cclinegraph'
import DiamondsTransactionBarChart from 'components/diamonds'
import FollowUnfollowBarChart from 'components/followsunfollows'
import CreatorCoinsRatioBarChart from 'components/ccheldholders'
import SkeletonCard from 'components/skeletoncard'
import { Skeleton } from "components/ui/skeleton"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CustomCommandInput,
} from "components/ui/command"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "components/ui/avatar"



export const DupeBlock = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [canSubmit, setCanSubmit] = useState(false);
  const [availabilityMessage, setAvailabilityMessage] = useState('');
  const [username, setUsername] = useState('');
  const debounceRef = useRef(null);
  const [userProfiles, setUserProfiles] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [hasStartedSearch, setHasStartedSearch] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const commandRef = useRef(null);
  const [isHuman, setIsHuman] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const [isScam, setIsScam] = useState(false);
  const [isAI, setIsAI] = useState(false);
  const [notEnoughTransactions, setNotEnoughTransactions] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [apiError, setApiError] = useState('');
  const [creatorCoinTransactions, setCreatorCoinTransactions] = useState('');
  const [selectedUserProfile, setSelectedUserProfile] = useState(null);
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalErrorReportMessage, setModalErrorReportMessage] = useState('');
  const [modalIsSendingErrorReport, setModalIsSendingErrorReport] = useState(false);
  const [modalErrorReportSent, setModalErrorReportSent] = useState(false);
  const [modalErrorReportSentComplete, setModalErrorReportSentComplete] = useState('');
  const [selectedUserBase64, setSelectedUserBase64] = useState('');
  const [selectedUserCCTransactions, setSelectedUserCCTransactions] = useState('');
  const [selectedUserNFTBuyNowTransactions, setSelectedUserNFTBuyNowTransactions] = useState('');
  const [selectedUserNFTBidTransactions, setSelectedUserNFTBidTransactions] = useState('');
  const [apiDebugMessage, setApiDebugMessage] = useState('');
  const [showBuyTokensButton, setShowBuyTokensButton] = useState(false);
  const [diamondsData, setDiamondsData] = useState({ sent: 0, received: 0 });
  const [followUnfollowData, setFollowUnfollowData] = useState({ follows: 0, unfollows: 0 });
  const [creatorCoinsData, setCreatorCoinsData] = useState({ held: 0, holders: 0 });
  const [searchUserInitiated, setSearchUserInitiated] = useState(false);
  const [nodeSelected, setNodeSelected] = useState('deso_core_node');
  const [progress, setProgress] = useState(0);
  



  const staticMessage = `Publickey searched: ${username}\nUsername searched: ${selectedUserProfile?.Username}\nDescribe Problem:\n`;
  const [userInput, setUserInput] = useState('');

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  function FeaturesCards() {
    const features = [
      {
        icon: <Shield size={ICON_SIZE} />,
        title: "Protect Yourself",
        description: "There are many bad actors on DeSo. Use our tool to help you evaluate your on-chain decisions and enjoy a better DeSo experience."
      },
      {
        icon: <DollarSign size={ICON_SIZE} />,
        title: "Public Beta",
        description: "Just 1 $desoscams token (~1 cent) per user check.",
        action: (
          <Button className="mt-2">
            <a href="https://openfund.com/d/desoscams" className="black-text-button" target="_blank" rel="noopener noreferrer">
              Buy Tokens
            </a>
          </Button>
        )
      },
      {
        icon: <List size={ICON_SIZE} />,
        title: "AI Based Evaluation",
        description: "Our system uses AI to evaluate each account."
      }
    ];

    return (
      <div className="flex justify-center m-2 lg:m-10">
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto">
          {features.map((feature, idx) => (
            <Card className="w-[350px]" key={idx}>
              <CardHeader className="flex flex-col items-center">
                {feature.icon}
                <CardTitle style={{ marginTop: '2rem' }} className="mt-6">{feature.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <p>{feature.description}</p>
                {feature.action}
                {/* Optional: Add any additional content per feature if needed */}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  function convertBase58ToBase64(pubKeyBase58) {
    // Convert base58 public key to a byte array using the provided utility function
    const pubKeyBytes = bs58PublicKeyToCompressedBytes(pubKeyBase58);

    // Convert the byte array to a base64 string
    const base64PubKey = btoa(String.fromCharCode(...pubKeyBytes));

    return base64PubKey;
  }

  const handleProfileSelect = (profile) => {
    setIsProfileSelected(true);
    setShowBuyTokensButton(false);
    setApiDebugMessage('');
    setApiResponse('');
    setApiError('');
    // Close the suggestions list
    setShowSuggestions(false);
    setHasStartedSearch(false);
    console.log("Selected User PublicKey:", profile);
    setSelectedUserProfile(profile);
    setUsername(profile.PublicKeyBase58Check);
    // Convert it to bytes using the utility function
    const publicKeyBase64 = convertBase58ToBase64(profile.PublicKeyBase58Check);

    setSelectedUserBase64(publicKeyBase64);
    console.log(publicKeyBase64);

    // Set the input value to the selected username
    setInputValue(profile?.Username);
    setCanSubmit(true);



    // Reset or stop the search
    setSearchQuery(''); // Setting this to an empty string or to the selected user, depending on your implementation
    // Additional logic here...
    setShowSuggestions(false);
  };

  const executeGraphQLQuery = async (query, variables) => {
    const response = await fetch('https://graphql-prod.deso.com/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query, variables }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  };

  const fetchDiamondFollowerData = async (publicKey) => {
    const query = `
      query Query($publicKey: String!, $filter: TransactionFilter, $tokenBalancesAsCreatorFilter2: TokenBalanceFilter, $tokenBalancesFilter2: TokenBalanceFilter) {
        accountByPublicKey(publicKey: $publicKey) {
          diamondsSent {
            totalCount
          }
          diamondsReceived {
            totalCount
          }
          followers {
            totalCount
          }
          following {
            totalCount
          }
          tokenBalances(filter: $tokenBalancesFilter2) {
            totalCount
          }
          tokenBalancesAsCreator(filter: $tokenBalancesAsCreatorFilter2) {
            totalCount
          }
          transactions(filter: $filter) {
            nodes {
              txnType
              txIndexMetadata
            }
          }
        }
      }`;

    const variables = {
      publicKey: publicKey,
      filter: {
        txnType: {
          equalTo: 9
        }
      },
      tokenBalancesAsCreatorFilter2: {
        hasPurchased: {
          equalTo: true
        }
      },
      tokenBalancesFilter2: {
        hasPurchased: {
          equalTo: true
        }
      }
    };

    try {
      const response = await executeGraphQLQuery(query, variables);
      if (response.data && response.data.accountByPublicKey) {
        return response.data.accountByPublicKey; // Return the relevant part of the response
      } else {
        throw new Error('No data returned');
      }
    } catch (error) {
      console.error('Error fetching diamond and follower data:', error);
      throw error; // Re-throw to handle it in the calling context
    }
  };

  const nftBuyNowTransactions = async (publicKey) => {
    let allTransactions = [];
    let afterCursor = null;

    const query = `
      query AffectedPublicKeys($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $condition: AffectedPublicKeyCondition, $orderBy: [AffectedPublicKeysOrderBy!]) {
          affectedPublicKeys(filter: $filter, condition: $condition, orderBy: $orderBy, first: $first, after: $after) {
              nodes {
                  transaction {
                      txnType
                      txIndexMetadata
                      timestamp
                  }
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          }
      }
  `;

    const fetchTransactions = async (after) => {
      const variables = {
        first: 5000,
        after: after,
        filter: {
          publicKey: {
            equalTo: publicKey
          },
          transaction: {
            txnType: {
              in: [18]
            },
            txIndexMetadata: {
              contains: {
                NFTRoyaltiesMetadata: {
                  CreatorPublicKeyBase58Check: publicKey
                },
                IsBuyNowBid: true
              }
            }
          }
        },
        condition: {
          isDuplicate: false
        },
        orderBy: "TIMESTAMP_DESC"
      };

      const response = await executeGraphQLQuery(query, variables);
      const transactions = response?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = response?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    } while (afterCursor);

    return allTransactions;
  };

  const nftBidTransactions = async (publicKey) => {
    let allTransactions = [];
    let afterCursor = null;

    const query = `
      query AffectedPublicKeys($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $condition: AffectedPublicKeyCondition, $orderBy: [AffectedPublicKeysOrderBy!]) {
          affectedPublicKeys(filter: $filter, condition: $condition, orderBy: $orderBy, first: $first, after: $after) {
              nodes {
                  transaction {
                      txnType
                      txIndexMetadata
                      timestamp
                  }
              }
              pageInfo {
                  endCursor
                  hasNextPage
              }
          }
      }
  `;

    const fetchTransactions = async (after) => {
      const variables = {
        first: 5000,
        after: after,
        filter: {
          publicKey: {
            equalTo: publicKey
          },
          transaction: {
            txnType: {
              in: [17]
            },
            txIndexMetadata: {
              contains: {
                NFTRoyaltiesMetadata: {
                  CreatorPublicKeyBase58Check: publicKey
                }
              }
            }
          }
        },
        condition: {
          isDuplicate: false
        },
        orderBy: "TIMESTAMP_DESC"
      };

      const response = await executeGraphQLQuery(query, variables);
      const transactions = response?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = response?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
    } while (afterCursor);

    return allTransactions;
  };



  const checkUserCreatorCoinTransactions = async (publicKey, base64PublicKey) => {
    let allTransactions = [];
    let afterCursor = null;
    let totalSellDeSo = 0;
    let totalBuyDeSo = 0;
    let numberOfSelfBuys = 0;
    let numberOfSelfSells = 0;
    let mostRecentSaleDate = null; // Track the most recent sale date

    const query = `
    query Query($first: Int, $after: Cursor, $filter: AffectedPublicKeyFilter, $orderBy: [AffectedPublicKeysOrderBy!], $condition: AffectedPublicKeyCondition) {
      affectedPublicKeys(filter: $filter, orderBy: $orderBy, condition: $condition, first: $first, after: $after) {
        nodes {
          transaction {
            txIndexMetadata
            timestamp
            publicKey
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    `;

    const fetchTransactions = async (after) => {
      const variables = {
        "first": 5000,
        "after": after,
        "filter": {
          "publicKey": {
            "equalTo": publicKey
          },
          "txnType": {
            "equalTo": 11
          },
          "transaction": {
            "txnMeta": {
              "contains": {
                "ProfilePublicKey": base64PublicKey
              }
            }
          }
        },
        "orderBy": "TIMESTAMP_DESC",
        "condition": {
          "isDuplicate": false
        }
      };

      const response = await fetch('https://graphql-prod.deso.com/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables })
      });

      const data = await response.json();
      const transactions = data?.data?.affectedPublicKeys?.nodes?.map(node => node.transaction);
      const pageInfo = data?.data?.affectedPublicKeys?.pageInfo;

      return { transactions, pageInfo };
    };

    do {
      const { transactions, pageInfo } = await fetchTransactions(afterCursor);
      allTransactions = allTransactions.concat(transactions);
      // Check if pageInfo exists and only then attempt to read hasNextPage and endCursor
      if (pageInfo) {
        afterCursor = pageInfo.hasNextPage ? pageInfo.endCursor : null;
      } else {
        // If pageInfo is undefined, ensure the loop exits by setting afterCursor to null
        afterCursor = null;
      }
    } while (afterCursor);

    // Process transactions
    allTransactions.forEach(tx => {
      const desoLockedNanosDiff = parseInt(tx.txIndexMetadata?.DESOLockedNanosDiff || 0) / 1e9; // Convert from nanos
      if (tx.publicKey === publicKey) {
        if (tx.txIndexMetadata?.OperationType === 'buy') {
          numberOfSelfBuys++;
          totalBuyDeSo += desoLockedNanosDiff;
        } else if (tx.txIndexMetadata?.OperationType === 'sell') {
          if (numberOfSelfSells === 0) { // This condition ensures we capture the first (most recent) sell transaction
            mostRecentSaleDate = tx.timestamp;
          }
          numberOfSelfSells++;
          totalSellDeSo += desoLockedNanosDiff;
        }
      }
    });

    setSelectedUserCCTransactions(allTransactions)

    return {
      numberOfSelfBuys,
      numberOfSelfSells,
      totalBuyDeSo,
      totalSellDeSo,
      mostRecentSaleDate, // Include the most recent sale date in the return object
      transactions: allTransactions
    };
  };



  const handleUsernameSearch = (username) => {
    setSearchUserInitiated(false);
    setIsSubmitting(false);
    setHasStartedSearch(false);
    setCanSubmit(true);
    setShowBuyTokensButton(false);
    setIsHuman(false);
    setIsBot(false);
    setIsScam(false);
    setIsAI(false);
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setApiDebugMessage('');
    setIsSearching(true); // Start searching
    setCanSubmit(false);
    setHasStartedSearch(true);
    const enteredUsername = username;
    setUsername(enteredUsername);

    // Clear any existing debounce timeout
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    // Set a new debounce timeout
    debounceRef.current = setTimeout(() => {
      const currentInputValue = inputValue.trim();
      if (currentInputValue.trim() !== '') {
        searchUsername(currentInputValue); // Use the currentInputValue for the search
        setShowSuggestions(true);
      }
    }, 300);
  };

  const extractPercentage = (response) => {
    try {
      // Parse the JSON string back into an object
      const parsedResponse = JSON.parse(response);
      // Now, check if the parsedResponse has a 'type' object and a 'score' field
      if (parsedResponse && parsedResponse.type && parsedResponse.type.score !== undefined) {
        return parsedResponse.type.score; // Directly return the score
      }
    } catch (error) {
      // Handle parsing error or the case where response is not a valid JSON string
      console.error("Failed to parse apiResponse:", error);
    }
    return 0; // Returns 0 if parsing fails, or if the 'type' object or 'score' field is not found
  };

  const sendErrorReport = () => {
    // Determine the additional message based on conditions
    let typeOfReport = '';
    if (isHuman) {
      typeOfReport = "False Negative";
    } else if (isAI || isBot || isScam) {
      typeOfReport = "False Positive";
    }

    // Construct the full message to send
    const messageToSend = (typeOfReport ? `${typeOfReport}\n` : "") + staticMessage + userInput;

    setModalErrorReportSentComplete('');
    setModalIsSendingErrorReport(true);
    // Send message to DeSoScams
    const sendMessageParams = {
      SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check,
      RecipientPublicKeyBase58Check: "BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx",
      Message: messageToSend, // Use the message from the modal
      AccessGroup: 'default-key',
    };

    sendMessage(sendMessageParams)
      .then(response => {
        console.log('Message sent successfully:', response);
        setModalIsSendingErrorReport(false);
        setModalErrorReportSent(true);
        setShowErrorModal(false);
        setModalErrorReportMessage('');
        setUserInput('')

        setModalErrorReportSentComplete('Message sent successfully! Thank you!');

        // Reset the success message state after a few seconds
        setTimeout(() => {
          setModalErrorReportSent(false);
          console.error('Timout sending message.');
          setModalIsSendingErrorReport(false);
          setModalErrorReportMessage('');
          setUserInput('')
          setModalErrorReportSentComplete('The message timed out. Please try again later.');
        }, 10000);
      })
      .catch(error => {
        console.error('Error sending message:', error);
        setModalIsSendingErrorReport(false);
        // Optionally handle error display in the UI
        setModalErrorReportMessage('');
        setUserInput('')
        setModalErrorReportSentComplete('Error sending message. Please try again later.');
      });
  };

  async function fetchDesoGuardData(username, txnHashHex) {
    // Prepare the data for the POST request
    const postData = {
      publickey: username,
      username: selectedUserProfile?.Username,
      daotransaction: txnHashHex,
      userPublicKey: currentUser.PublicKeyBase58Check
    };

    try {
      const response = await fetch('https://2lcwfajr7c.execute-api.us-west-2.amazonaws.com/production/desoguard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        // Throw an error with the response status
        console.error(`Error: ${response.status}`);
        setApiError(`Error: ${response.status}`);
        return
      }

      const data = await response.json();
      console.log('API Response:', data);
      if (data.statusCode === 500) {
        let responseBody;
        try {
          responseBody = JSON.parse(data.body);
        } catch (error) {
          // Handle case where JSON parsing fails
          console.error("Error parsing response body:", error);
          setApiError("An unexpected error occurred.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
          return;
        }
  
        // Check for specific error message and handle accordingly
        if (responseBody && typeof responseBody === 'string' && responseBody.includes("cannot unpack non-iterable NoneType object")) {
          setApiError("Sorry, there was an error retrieving data for this profile.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
        } else {
          // Handle other potential errors or default to showing the parsed message
          setApiError(responseBody || "An unexpected error occurred.");
          setIsSubmitting(false);
          setSearchUserInitiated(false);
        }
      } else if (!response.ok) {
        // Handle non-2xx responses if any
        console.error(`Error: ${response.status}`);
        setApiError(`Error: ${response.status}`);
        setIsSubmitting(false);
        setSearchUserInitiated(false);
      }
      if (data.body) {
        // Parse the JSON string inside the `body` field
        const responseBody = JSON.parse(data.body);

        // Check if the parsed object has a `debug` field and it's not empty
        if (responseBody.debug && responseBody.debug !== "") {
          setApiDebugMessage(responseBody.debug);
        }
      }


      // Correctly parse the response body as JSON
      const responseBody = JSON.parse(data.body);
      return responseBody;

    } catch (error) {
      console.error("Error fetching data:", error);
      setApiError("Error fetching data:", error);
      return { error: `Error fetching data: ${error.message}` };
    }
  }

  async function submitDupeBlockUsername() {
    setProgress(0);
    setSearchUserInitiated(true)
    setShowBuyTokensButton(false);
    setIsSubmitting(true);
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setCreatorCoinTransactions('');
    setSelectedUserNFTBuyNowTransactions('');
    setSelectedUserNFTBidTransactions('');
    console.log(nodeSelected);
    let blockAPIurl;
    if (nodeSelected === 'deso_core_node') {
        configure({ nodeURI: 'https://node.deso.org' });
        blockAPIurl = 'https://node.deso.org/api/v0/block-public-key';
    } else if (nodeSelected === 'desocialworld') {
        configure({ nodeURI: 'https://desocialworld.com' });
        blockAPIurl = 'https://desocialworld.com/api/v0/block-public-key';
    } else if (nodeSelected === 'diamond') {
        configure({ nodeURI: 'https://diamondapp.com' });
        blockAPIurl = 'https://diamondapp.com/api/v0/block-public-key';
    }

    let syncUserStateless = await getUsersStateless({
        PublicKeysBase58Check: [selectedUserProfile?.PublicKeyBase58Check, currentUser.PublicKeyBase58Check],
    });
    console.log(syncUserStateless);

    // Use a Set to store the blocked public keys to ensure uniqueness
    let blockedPubKeysSet = new Set();

    // Loop through each user in the UserList
    syncUserStateless.UserList.forEach(user => {
        // Add the keys from the BlockedPubKeys object to the Set
        Object.keys(user.BlockedPubKeys).forEach(key => blockedPubKeysSet.add(key));
    });

    // Convert the Set back to an array
    let blockedPubKeysArray = Array.from(blockedPubKeysSet);

    console.log(blockedPubKeysArray);

    let userJWT = await identity.jwt();

    let totalKeys = blockedPubKeysArray.length;
    let processedKeys = 0;

    async function blockPublicKey(blockedPubKey) {
        try {
            console.log(`Attempting to block: ${blockedPubKey}`);
            const response = await fetch(blockAPIurl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "JWT": userJWT,
                    "PublicKeyBase58Check": currentUser.PublicKeyBase58Check,
                    "BlockPublicKeyBase58Check": blockedPubKey,
                    "Unblock": false
                })
            });
            const responseData = await response.json();
            console.log(`Successfully blocked: ${blockedPubKey}, Response: `, responseData);
        } catch (error) {
            console.error(`Error blocking ${blockedPubKey}: `, error);
        }
    }

    for (const blockedPubKey of blockedPubKeysArray) {
        await blockPublicKey(blockedPubKey);
        processedKeys++;
        // Calculate progress as an integer percentage of the completed tasks
        const currentProgress = Math.round((processedKeys / totalKeys) * 100);
        setProgress(currentProgress); // Update progress state
    }

    console.log(`Completed blocking requests for ${blockedPubKeysArray.length} users.`);
    setSearchUserInitiated(false)
    setIsSubmitting(false);
}

  async function submitUsername() {
    setSearchUserInitiated(true)
    setShowBuyTokensButton(false);
    setIsSubmitting(true);
    setIsHuman(false);
    setIsBot(false);
    setIsScam(false);
    setIsAI(false); // Reset the AI state
    setNotEnoughTransactions(false);
    setApiResponse('');
    setApiError('');
    setCreatorCoinTransactions('');
    setSelectedUserNFTBuyNowTransactions('');
    setSelectedUserNFTBidTransactions('');

    try {
      // Assuming identity is available in this scope
      if (
        !identity.hasPermissions({
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        })
      ) {
        // if the user doesn't have permissions, request them
        // and abort the submit
        await identity.requestPermissions({
          GlobalDESOLimit: 10000000, // 0.01 DESO
          TransactionCountLimitMap: {
            NEW_MESSAGE: "UNLIMITED"
          },
          DAOCoinOperationLimitMap: {
            BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {
              transfer: "UNLIMITED",
            }
          }
        });
      }

      const Enums = {
        values: {
          NANO_VALUE: 1e9, // Assuming this represents 1 billion
          HEX_PREFIX: '0x'
        }
      };
      const firstAmount = 1.0 * Enums.values.NANO_VALUE * Enums.values.NANO_VALUE; // Convert amount to "nano-nanos"
      const amountNanos = new BigNumber(firstAmount); // Convert amount to BigNumber
      const hexAmount = amountNanos.toString(16); // Convert BigNumber to hexadecimal string
      const finalAmount = Enums.values.HEX_PREFIX + hexAmount; // Add hex prefix

      // Ensure transferDeSoToken is defined and correctly handles promises
      await transferDeSoToken({
        ProfilePublicKeyBase58CheckOrUsername: 'BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx',
        ReceiverPublicKeyBase58CheckOrUsername: 'BC1YLfx8tRyEJ1gDLbybbiZocwrUijyyA2ydjpyXe11RakuFDs2gKmx',
        DAOCoinToTransferNanos: finalAmount,
        SenderPublicKeyBase58Check: currentUser.PublicKeyBase58Check
      }).then(async (response) => {
        console.log('Transfer successful:', response);

        // Handle responses
        const txnHashHex = response.submittedTransactionResponse.TxnHashHex;
        const promises = [
          fetchDesoGuardData(username, txnHashHex).then(processDesoGuardResponse),
          checkUserCreatorCoinTransactions(username, selectedUserBase64).then(processCreatorCoinData),
          nftBuyNowTransactions(username).then(setSelectedUserNFTBuyNowTransactions),
          fetchDiamondFollowerData(username).then(processDiamondFollowerData),
          nftBidTransactions(username).then(setSelectedUserNFTBidTransactions)
        ];

        await Promise.allSettled(promises);
      }).catch(error => {
        console.error('Error transferring token:', error);
        // Set an appropriate error message
        setApiResponse("");
        setApiError("There was a problem transferring the token. Please check your balance and permissions:", error);
        setShowBuyTokensButton(true);
        setIsSubmitting(false);
      });
    } catch (error) {
      console.error('Error during the operation:', error);
      setApiError("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const processDiamondFollowerData = (data) => {
    // Process diamonds data
    setDiamondsData({
      sent: data.diamondsSent.totalCount,
      received: data.diamondsReceived.totalCount,
    });

    // Initialize counters
    let follows = 0;
    let unfollows = 0;

    // Process follow/unfollow data
    data.transactions.nodes.forEach(node => {
      if (node.txnType === 9) { // Assuming 9 is the transaction type for follow/unfollow
        if (node.txIndexMetadata.IsUnfollow) {
          unfollows++;
        } else {
          follows++;
        }
      }
    });

    // Update state for follow/unfollow data
    setFollowUnfollowData({
      follows,
      unfollows,
    });
    // Process and set "Creator Coins Held/Holders" data
    setCreatorCoinsData({
      held: data.tokenBalances.totalCount,
      holders: data.tokenBalancesAsCreator.totalCount,
    });
  };

  function processDesoGuardResponse(desoGuardResponse) {
    // Assuming desoGuardResponse has a 'type' property that indicates the classification
    if (desoGuardResponse.type.type) {
      console.log(desoGuardResponse);
      switch (desoGuardResponse.type.type) {
        case "AI":
          setIsAI(true);
          break;
        case "Scam":
          setIsScam(true);
          break;
        case "Bot":
          setIsBot(true);
          break;
        case "Not Enough Transactions":
          setNotEnoughTransactions(true);
          break;
        default:
          setIsHuman(true);
      }
      setApiResponse(JSON.stringify(desoGuardResponse));
    } else {
      // If the response does not contain a type, consider the user as human by default
      setIsHuman(true);
    }

  }

  function processCreatorCoinData(creatorCoinData) {
    let transactionMessage = '';
    const { numberOfSelfBuys, totalBuyDeSo, numberOfSelfSells, totalSellDeSo, mostRecentSaleDate } = creatorCoinData;

    if (numberOfSelfSells === 0) {
      transactionMessage = "They have never sold their creator coin.";
    } else {
      transactionMessage = `They have sold their creator coin ${numberOfSelfSells} times, totaling ${Math.abs(totalSellDeSo).toFixed(2)} DeSo`;
      if (mostRecentSaleDate) {
        const today = new Date();
        const lastSaleDate = new Date(mostRecentSaleDate);
        const differenceInDays = Math.floor((today - lastSaleDate) / (1000 * 3600 * 24));
        transactionMessage += `. It has been ${differenceInDays} days since the last sale.`;
      }
    }

    if (numberOfSelfBuys > 0) {
      transactionMessage += ` Additionally, they have bought their own creator coin ${numberOfSelfBuys} times, totaling ${totalBuyDeSo.toFixed(2)} DeSo.`;
    }

    // Update the state with the constructed message
    setCreatorCoinTransactions(transactionMessage);
  }


  const searchUsername = async (username) => {
    console.log("searching usernames");
    try {
      if (username.trim() === '') {
        setAvailabilityMessage('');
        setUserProfiles([]);
        setApiError('');
        return;
      }

      const payload = {
        PublicKeyBase58Check: "",
        Username: "",
        UsernamePrefix: username.trim(),
        Description: "",
        OrderBy: "",
        NumToFetch: 5,
        ReaderPublicKeyBase58Check: "BC1YLfx8tRyEJ1gDLbybbiZocwrUijyyA2ydjpyXe11RakuFDs2gKmx",
        ModerationType: "",
        FetchUsersThatHODL: false,
        AddGlobalFeedBool: false
      };

      const response = await fetch('https://node.deso.org/api/v0/get-profiles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.ProfilesFound && data.ProfilesFound.length > 0) {
        setUserProfiles(data.ProfilesFound);
        setIsSearching(false);
      } else {
        setUserProfiles([]);
        setIsSearching(false);
        setAvailabilityMessage('No user profiles found.');
      }
    } catch (error) {
      setIsSearching(false);
      console.error('Error searching username:', error);
      setAvailabilityMessage('Error searching username.');
    }
  };


  const getProfilePictureUrl = (user) => {
    if (!user || !user.PublicKeyBase58Check) {
      // Return a default image URL or handle the lack of user data as needed
      return 'https://bitclout.com/assets/img/default_profile_pic.png';
    }
    return `https://diamondapp.com/api/v0/get-single-profile-picture/${user.PublicKeyBase58Check}?fallback=https://bitclout.com/assets/img/default_profile_pic.png`;
  };


  useEffect(() => {
    if (inputValue.trim() === '') {
      console.log("useEffect empty");
      setUserProfiles([]);
      setSelectedUserProfile(null);
      setApiError('');
    } else if (!isProfileSelected) {
      // Only call handleUsernameSearch if the change was not due to a profile selection
      setSelectedUserProfile(null);
      setShowSuggestions(true);
      handleUsernameSearch(inputValue);
    } else {
      // Reset the flag after handling the selection
      setIsProfileSelected(false);
    }
  }, [inputValue]);
  useEffect(() => {
    // Function to check if clicked outside of command
    function handleClickOutside(event) {
      if (commandRef.current && !commandRef.current.contains(event.target)) {
        setShowSuggestions(false);
        console.log("Click Outside")
      }
    }


    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commandRef]);

  return (

    <div>
      {showErrorModal &&
        <Dialog open={showErrorModal} onOpenChange={setShowErrorModal}>
          <DialogTrigger asChild>
            <button className="hidden">Open</button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle className="text-lg leading-6 font-medium text-white">Report an Error</DialogTitle>
              <DialogDescription className="mt-2 text-gray-300">
                Please describe the error you encountered.
              </DialogDescription>
            </DialogHeader>
            <Textarea
              onChange={handleInputChange}
            />
            <div className="px-1 py-3 sm:px-2 sm:flex sm:flex-row-reverse">
              <Button
                type="button"
                onClick={sendErrorReport}
                disabled={modalIsSendingErrorReport}
              >
                {modalIsSendingErrorReport ? <>
                  <Loader2 className="animate-spin" />
                  Sending...
                </> : 'Send'}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      }
      {(!isLoading || userPrefs !== null) && (
        <>
          <div className="flex justify-center items-center m-4">
            <img src={logo} alt="Logo" className="w-full md:w-1/2 xl:w-1/3 h-auto" />
          </div>
          <h1 className="text-1xl font-extrabold leading-tight tracking-tighter lg:text-4xl mt-1 text-center">
             Sync Blocked Users
          </h1>
        </>
      )}

      {!isLoading && !currentUser && (
        <div className="mb-4 text-center">
          <p className="mt-5 mx-2 mb-3 text-gray-400">Login with your DeSo identity and approve the derived key to proceed.</p>
          <Button onClick={() => identity.login()}>
            Login
          </Button>
        </div>
      )}
      {!isLoading && currentUser && formSubmitted && (
        <div className="p-2 mt-2 text-center">
          <p className="mb-2 mx-2">Thank you, your sync preferences have been updated.</p>
          <Button onClick={() => {
            setFormSubmitted(false);
            setMessage("");
          }}>
            Change Preferences
          </Button>
        </div>
      )}

      {!isLoading && currentUser && !formSubmitted && (!userPrefs || resetTwitterAPI) && (
        <div className="flex flex-col p-4 text-center w-full lg:w-1/2 mx-auto">
          {(!resetTwitterAPI) ?
            <>
            <p className="mt-5 mx-2 mb-3 text-gray-400 ">Our app lets you effortlessly sync blocked user lists from other users, ensuring a unified approach to creating a safer, more positive experience on DeSo. Select a user you want to sync blocked users from as well as the node to use (because blocked users are node specific):</p>
              <div className="flex flex-col items-start w-full lg:w-1/2 gap-1.5 mx-auto mt-3 relative">
                <Command className="rounded-lg border shadow-md" shouldFilter={false} ref={commandRef}>
                  <CustomCommandInput
                    placeholder="Search for a user..."
                    value={inputValue}
                    onValueChange={(newValue) => {
                      setInputValue(newValue);
                      setIsProfileSelected(false);
                    }}
                    selectedUserProfile={selectedUserProfile}
                  />

                  {showSuggestions && (
                    <CommandList>
                      {isSearching ? (
                        <CommandEmpty>Searching...</CommandEmpty>
                      ) : userProfiles.length > 0 ? (
                        <CommandGroup>
                          {userProfiles.map((profile) => (
                            <CommandItem
                              key={profile.PublicKeyBase58Check}
                              onSelect={() => handleProfileSelect(profile)}
                            >
                              <Avatar>
                                <AvatarImage src={getProfilePictureUrl(profile)} alt={profile?.Username} />
                                <AvatarFallback>{profile?.Username[0]}</AvatarFallback>
                              </Avatar>
                              <div className="ml-2">
                                <p className="text-sm font-medium leading-none">
                                  {profile?.Username}
                                </p>
                                {/* Additional details here if needed */}
                              </div>

                            </CommandItem>
                          ))}
                        </CommandGroup>
                      ) : (
                        <CommandEmpty>No user profiles found.</CommandEmpty>
                      )}
                    </CommandList>
                  )}
                </Command>



              </div>
              <div className="flex flex-col items-center w-full lg:w-1/2 gap-1.5 mx-auto mt-3 relative mt-3">
            <Select onValueChange={setNodeSelected}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select Node" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="deso_core_node" selected>DeSo Core Node</SelectItem>
                  <SelectItem value="desocialworld">DeSocialWorld</SelectItem>
                  <SelectItem value="diamond">Diamond</SelectItem>
                </SelectContent>
              </Select>
              </div>





            </> : <> </>}





          {(!resetTwitterAPI) ? <div className="mt-3">
            <Button
              type="submit"
              disabled={isSubmitting || !canSubmit}
              onClick={submitDupeBlockUsername}
            >
              {canSubmit
                ? (isSubmitting
                  ? (
                    <>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Please Wait
                    </>
                  )
                  : <>Submit</>
                )
                : <>Select a User</>}
            </Button>
            
          </div>
          


            : <><div className="container m-auto grid grid-cols-2 md:grid-cols-2 gap-4"><div className="mt-3">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setResetTwitterAPI(false);
                  setMessage("");
                }}>Cancel
              </Button>
            </div>
              <div className="mt-3">
                <Button
                  type="submit"

                  disabled={isSubmitting}
                >
                  {isSubmitting
                    ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Please Wait
                      </>
                    )
                    : <>Submit</>}
                </Button>
              </div>
              

            </div>
            </>
          }
          { isSubmitting ? (
            <div className="flex flex-row items-center justify-center w-full lg:w-1/3 mx-auto mt-6 relative gap-1.5">
            <Progress value={progress} className="w-[90%]" />
            </div>
          ) : ""}
          

          


          {message && <p className="mt-2 text-red-600">{message}</p>}

          {apiError && (
            <div className="error-message mt-2 text-red-600">{apiError}</div>
          )}



          {searchUserInitiated && showBuyTokensButton && (
            <Button className="mt-2">
              <a href="https://openfund.com/d/desoscams" className="black-text-button" target="_blank" rel="noopener noreferrer">
                Buy Tokens
              </a>
            </Button>
          )}

          {apiDebugMessage && (
            <div className="error-message mt-2 text-red-600">{apiDebugMessage}</div>
          )}
          

        </div>
      )}
      {(!isLoading) ? <FeaturesCards /> : ""}



    </div>

  );
}