import * as React from "react"

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from "components/ui/command"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "components/ui/avatar"


const cats = ["Siamese", "British Shorthair", "Maine Coon", "Persian", "Ragdoll", "Sphynx"]
const dogs = ["German Shepherd", "Bulldog", "Labrador Retriever", "Golden Retriever", "French Bulldog", "Siberian Husky"]

const mockApiSearch = (searchQuery) => {
  const lookingForCats = searchQuery.includes("cat")
  const lookingForDogs = searchQuery.includes("dog")
  if (lookingForCats && lookingForDogs) {
    return [...cats, ...dogs]
  } else if (lookingForCats) {
    return cats
  } else if (lookingForDogs) {
    return dogs
  } else {
    return []
  }
}

export const Home2 = () => {
  const [commandInput, setCommandInput] = React.useState("")
  const [results, setResults] = React.useState([])
  React.useEffect(() => {
    setResults(mockApiSearch(commandInput))
  }, [commandInput])

  return (
    <Command className="rounded-lg border shadow-md" shouldFilter={false}>
      <CommandInput placeholder="Type 'cat' or 'dog'..." value={commandInput} onValueChange={setCommandInput} />
      <CommandList>
        <CommandEmpty>{ commandInput === "" ? "Start typing to load results": "No results found." }</CommandEmpty>
        <CommandGroup>
          {
            results.map((result) => <CommandItem key={result} value={result}>

              { result }
            </CommandItem>)
          }
        </CommandGroup>
      </CommandList>
    </Command>
  )
}