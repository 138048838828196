import { useContext, useState, useEffect } from "react";
import { useForm, Controller } from 'react-hook-form';
import { DeSoIdentityContext } from "react-deso-protocol";
import { getDisplayName } from "../helpers";
import { identity } from "deso-protocol";
import { Link } from "react-router-dom";
import userPrefsStore from 'context/userPrefsStore';
import { Repeat, DollarSign, List, Shield, Video, Link as LinkIcon, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button"
import { Label } from "components/ui/label"
import { Input } from "components/ui/input"
import { Switch } from "components/ui/switch"
import { Separator } from "components/ui/separator"
import {
  Avatar,
  AvatarFallback,
} from "components/ui/avatar"

export const GetKeys = () => {
  const ICON_SIZE = 48;
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [resetTwitterAPI, setResetTwitterAPI] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isFormVisible, setFormVisible] = useState(true);
  const [isSyncEnabled, setIsSyncEnabled] = useState(true);  // Default to true (enabled)
  const [hasHashtagError, setHasHashtagError] = useState(false);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  function linkify(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, url => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
}

  function TutorialCards() {
    const tutorialSteps = [
        {
            step: 1,
            image: '',
            description: 'Welcome to the DeSoSync Beta! DeSoSync will sync your posts from DeSo to Twitter. Reposts and Quotes will not be synced. Also, we currently support YouTube, Giphy and Spotify embeds, but all others will be added as a link. Native DeSo video is not currently synced. During the Beta, every synced post will cost $0.001. After the Beta cost will be around $0.01 per post synced (TBD). Let\'s get on with how to setup your account!'
        },
        {
            step: 2,
            image: '',
            description: 'Be sure you are logged in with the Twitter account you want to sync with then navigate to <a href="https://developer.twitter.com/en" target="_blank">https://developer.twitter.com/en</a>'
        },
        {
            step: 3,
            image: '1',
            description: 'Click "Developer Portal"'
        },
        {
            step: 4,
            image: '2',
            description: 'Click "Sign up for Free Account"'
        },
        {
            step: 5,
            image: '3',
            description: 'Click this field.'
        },
        {
            step: 6,
            image: '',
            description: 'Write your own reason or use this: I will use my access to the Twitter API to implement an advanced integration that enables seamless synchronization of posts between multiple platforms. By employing this tool, I aim to eradicate the tedious process of manual reposting. This not only assures consistency and uniformity in content distribution but also dramatically enhances time management and overall productivity for content creators. The value added by this integration is immeasurable.'
        },
        {
            step: 7,
            image: '4',
            description: 'Click all the checkboxes.'
        },
        {
            step: 8,
            image: '5',
            description: 'Click "Submit"'
        },
        {
            step: 9,
            image: '6',
            description: 'Click the key icon.'
        },
        {
            step: 10,
            image: '7',
            description: 'Click "Regenerate"'
        },
        {
            step: 11,
            image: '8',
            description: 'Click "Yes, regenerate"'
        },
        {
            step: 12,
            image: '9',
            description: 'Click "Copy" and paste somewhere safe (you\'ll need this later).'
        },
        {
            step: 13,
            image: '10',
            description: 'Click "Copy" and paste somewhere safe (you\'ll need this later).'
        },
        {
            step: 14,
            image: '11',
            description: 'Click "Yes, I saved them"'
        },
        {
            step: 15,
            image: '12',
            description: 'Click "Projects & Apps". NOTE: If you have an existing app that you used previously, it might be better to create a new one with new authorization.'
        },
        {
            step: 16,
            image: '13',
            description: 'Click on your project id'
        },
        {
            step: 17,
            image: '14',
            description: 'Click "Set up" under the User authentication settings'
        },
        {
            step: 18,
            image: '15',
            description: 'Click the "Read and write" radio button.'
        },
        {
            step: 19,
            image: '16',
            description: 'Click the "Web App" radio button.'
        },
        {
            step: 20,
            image: '17',
            description: 'Click the "https:// or scheme://" field and type in https://desosync.me'
        },
        {
            step: 21,
            image: '18',
            description: 'Also enter https://desosync.me in the Website URL'
        },
        {
            step: 22,
            image: '19',
            description: 'Click "Save"'
        },
        {
            step: 23,
            image: '20',
            description: 'Click "Yes"'
        },
        {
            step: 24,
            image: '21',
            description: 'Click "Done"'
        },
        {
            step: 25,
            image: '22',
            description: 'Click "Yes, I saved it" (you don\'t need this key for DeSo Sync)'
        },
        {
            step: 26,
            image: '23',
            description: 'Click "Keys and tokens"'
        },
        {
            step: 27,
            image: '24',
            description: 'Click "Regenerate"'
        },
        {
            step: 28,
            image: '25',
            description: 'Click "Yes, regenerate"'
        },
        {
            step: 29,
            image: '26',
            description: 'Click "Copy" and paste in a safe place.'
        },
        {
            step: 30,
            image: '27',
            description: 'Click "Copy" and paste in a safe place.'
        },
        {
            step: 31,
            image: '28',
            description: 'Click "Yes, I saved them"'
        },
        {
            step: 32,
            image: '',
            description: 'Then head back to the signup form and login with the DeSo account you want to sync, and then paste the keys and secrets in their corresponding inputs and press submit and you\'ll be all set!'
        }
    ];
    return (
      <div className="flex m-2 lg:m-10">
        <div className="grid gap-10 grid-cols-1 w-full lg:max-w-screen-xl mx-auto">
          {tutorialSteps.map((tutorialStep, idx) => (
            <div key={idx}>
              <Card key={idx} className="w-full lg:w-1/2 lg:max-w-3xl mx-auto">
                <CardHeader className="flex flex-col justify-center items-center">
                  <div className="order-1 lg:order-none">
                    <Avatar>
                      <AvatarFallback>{idx + 1}</AvatarFallback>
                    </Avatar>
                  </div>
                  <div className="order-2 lg:order-none">
                  <CardTitle className="ml-4 leading-7 lg:ml-4" dangerouslySetInnerHTML={{ __html: tutorialStep.description }}>
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardContent>
                  {tutorialStep.image && <img src={`/assets/tutorial/${tutorialStep.image}.png`} alt={`Tutorial step ${idx + 1}`} className="rounded" />}
                  {/* Optional: Add any additional content per feature if needed */}
                </CardContent>
              </Card>
              <Separator orientation="vertical" className="mx-auto" />
            </div>
          ))}
    
        </div>
      </div>
    );
}

  function FeaturesCards() {
    const features = [
        {
            icon: <Repeat size={ICON_SIZE} />,
            title: "Automatic Synchronization",
            description: "Directly sync your DeSo posts to Twitter."
        },
        {
            icon: <DollarSign size={ICON_SIZE} />,
            title: "Cost-Effective Open Beta",
            description: "Only $0.001USD per post synced."
        },
        {
            icon: <List size={ICON_SIZE} />,
            title: "Intuitive Auto-Threading",
            description: "Our system automatically adds the 🧵 emoji to threaded posts"
        },
        {
            icon: <Shield size={ICON_SIZE} />,
            title: "Original Content Focus",
            description: "We ensure only your original posts are synced – no re-posts or quoted posts."
        },
        {
            icon: <Video size={ICON_SIZE} />,
            title: "Video Synchronization",
            description: "Seamlessly sync your native DeSo LivePeer videos."
        },
        {
            icon: <LinkIcon size={ICON_SIZE} />,
            title: "Multimedia Embed Support",
            description: "Giphy, YouTube, and Spotify embeds are fully supported."
        }
    ];

    return (
      <div className="flex justify-center m-2 lg:m-10">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-screen-xl mx-auto">
            {features.map((feature, idx) => (
                <Card className="w-[350px]" key={idx}>
                    <CardHeader className="flex flex-col items-center">
                        {feature.icon}
                        <CardTitle style={{ marginTop: '2rem' }} className="mt-6">{feature.title}</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p>{feature.description}</p>
                        {/* Optional: Add any additional content per feature if needed */}
                    </CardContent>
                </Card>
            ))}
        </div>
        </div>
    );
}

  return (
    <div>
    {(!isLoading) && (
      <>
      <h1 className="text-1xl font-extrabold leading-tight tracking-tighter lg:text-4xl mt-1 text-center">
      How to Get Your Twitter API Keys
      </h1>
      <a href="/" rel="noopener noreferrer">
        <Button className="mt-2 mb-2">Back to Signup Form</Button>
      </a>
      <TutorialCards />
      <a href="/" rel="noopener noreferrer">
        <Button className="mt-2 mb-2">Back to Signup Form</Button>
      </a>
      

      </>
    )}
    </div>
  );
}
