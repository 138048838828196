import { Skeleton } from "components/ui/skeleton"
 
export function SkeletonCard() {
  return (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-[300px] w-full rounded-xl" />
    </div>
  )
}

export default SkeletonCard;