import { configure } from "deso-protocol";
import { useContext, useEffect, useState } from "react";
import { DeSoIdentityContext } from "react-deso-protocol";
import userPrefsStore from 'context/userPrefsStore';
import { Loader2 } from 'lucide-react';
import { Outlet } from "react-router-dom";
import { Nav } from "../components/nav";

configure({
  spendingLimitOptions: {
    GlobalDESOLimit: 10000000, // 0.01 DESO
      TransactionCountLimitMap: {
        NEW_MESSAGE: "UNLIMITED"
      },
      DAOCoinOperationLimitMap: { 
        BC1YLhTs2vNbPa8FyrKucvLBs8hEyKyEUHAQgB7HUixzbfAJapmzQjx: {  
        transfer: "UNLIMITED",
        }
   },
  },
});


export const Root = () => {
  const { currentUser, isLoading } = useContext(DeSoIdentityContext);
  const [ isUserLoading, setIsUserLoading ] = useState(true);
  const { userPrefs, setUserPrefs } = useContext(userPrefsStore);
  const [loading, setLoading] = useState(true);

  
  const getDerivedData = () => {
    if (currentUser && currentUser.PublicKeyBase58Check) {
      const desoIdentityUsers = localStorage.getItem("desoIdentityUsers");
      const parsedData = JSON.parse(desoIdentityUsers);
      const targetUserKey = currentUser.PublicKeyBase58Check;

      return {
        derivedPublicKey: parsedData[targetUserKey]['primaryDerivedKey'].derivedPublicKeyBase58Check,
        derivedSeedHex: parsedData[targetUserKey]['primaryDerivedKey'].derivedSeedHex,
        derivedDeSo: parsedData[targetUserKey]['primaryDerivedKey']['transactionSpendingLimits'].GlobalDESOLimit
      };
    }
    return { derivedPublicKey: null, derivedSeedHex: null, derivedDeso: null };
  }

  useEffect(() => {
    setLoading(true);
    if (currentUser) {
      setLoading(false);
      setUserPrefs(false);
      console.log('isUserLoading:', isUserLoading);
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  return (
  <>
    <Nav />
    <div role="main" className="main-content">
      <div className={`main-content ${!loading ? 'loaded' : ''}`}>
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader2 className="mr-2 h-10 w-10 animate-spin" />
            <div className="4xl">Loading...</div>
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  </>
);
  
};
