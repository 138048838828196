import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis, Tooltip, Cell, CartesianGrid } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip" style={{
                backgroundColor: '#20232a',
                borderColor: '#20232a', padding: '5px'
            }}>
                <p className="label">{`${label}: ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

const DiamondsTransactionBarChart = ({ diamondsData }) => {
    // Data format for BarChart
    const chartData = [
        {
            name: "Given", // Adjusted to reflect the label
            value: diamondsData.sent, // Use a generic key for value
        },
        {
            name: "Received", // Adjusted to reflect the label
            value: diamondsData.received, // Use a generic key for value
        }
    ];

    return (
        <Card>
            <CardHeader>
                <CardTitle>Diamonds Ratio</CardTitle>
            </CardHeader>
            <CardContent>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                        data={chartData}
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        barCategoryGap={10}
                        barGap={10}
                    >
                        <defs>
                            <linearGradient id="gradientBar" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.25} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.05} />
                            </linearGradient>
                            <linearGradient id="gradientBar2" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.05} />
                                <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.0} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            dataKey="name"
                            stroke="#888888"
                            fontSize={12}
                            tickLine={false}
                            axisLine={false}
                        />
                        <YAxis
                            stroke="#888888"
                            fontSize={12}
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={(value) => `${value}`}
                        />
                        <CartesianGrid horizontal={true} vertical={false} stroke="#20232a" strokeWidth={1} />
                        <Tooltip
                            cursor={false}
                            content={<CustomTooltip />}
                            contentStyle={{
                                backgroundColor: '#20232a',
                                borderColor: '#20232a'
                            }}
                            itemStyle={{ color: '#fff' }}
                        />
                        <Bar dataKey="value" fill="url(#gradientBar)" radius={[10, 10, 0, 0]}>
                            {
                                chartData.map((entry, index) => {
                                    if (entry.name === "Received") {
                                        // Attempt to overlay or use a distinct visual marker
                                        // This is conceptual; actual implementation might differ
                                        return <Cell key={`cell-outline-${index}`} fill="url(#gradientBar2)" stroke="#FFFFFF" strokeWidth={2} />;
                                    }
                                    return <Cell key={`cell-${index}`} fill="url(#gradientBar)" stroke="#FFFFFF" strokeWidth={2} />;
                                })
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
};

export default DiamondsTransactionBarChart;




