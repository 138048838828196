import React, { PureComponent } from 'react';
import { PieChart, Pie, ResponsiveContainer, Label } from 'recharts';

function CustomLabel({ viewBox, percentage }) {
  const { cx, cy } = viewBox;
  return (
    <text x={cx} y={cy} fill="#FFFFFF" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
      <tspan alignmentBaseline="top" fontSize="55" fontWeight="700">{percentage}%</tspan>
      <tspan fontSize="24" x={cx} dy="1.6em" fontWeight="400">Certainty</tspan>
    </text>
  );
}

export default class PercentagePieChart extends PureComponent {
  render() {
    const { percentage } = this.props;
    const endAngle = 180 - (percentage / 100 * 180);
    const data = [
      { name: 'Percentage', value: percentage, color: '#e71d36' },
    ];
    const backgroundData = [
      { name: 'Background', value: 100, color: '#292A3C' }, // Full range with a gray color
    ];

    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart margin={{ top: 0, right: 0, bottom: -100, left: 0 }}>
          {/* Background Pie */}
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0} // Ensures full 180 degrees are covered
            data={backgroundData}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="90%"
            fill={backgroundData[0].color} // Use the gray color
            stroke="none"
            isAnimationActive={false} // Disable animation for the background pie
          />
          
          {/* Foreground Pie for Percentage */}
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={endAngle}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="60%"
            outerRadius="90%"
            fill="#e71d36"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Label
                content={<CustomLabel percentage={percentage} />}
                position="center"
                key={`label-${index}`}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
