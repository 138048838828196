import React, { useMemo } from 'react';
import {
  AreaChart, Area, ResponsiveContainer, Tooltip,
  XAxis, YAxis, CartesianGrid
} from 'recharts';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "components/ui/card";

const getMonthYearString = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
                      "July", "August", "September", "October", "November", "December"];
  return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

const calculateMonthlyLockedDeso = (CCTransactions, NFTBuyNowTransactions, NFTBidTransactions) => {
  let allTransactions = [...CCTransactions, ...NFTBuyNowTransactions, ...NFTBidTransactions].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  let monthlyLockedDeso = {};
  let cumulativeDeso = 0;

  allTransactions.forEach((tx) => {
      const date = new Date(tx.timestamp);
      const monthYear = getMonthYearString(date);
      let desoLockedNanos = 0;

      if (tx.hasOwnProperty('txIndexMetadata')) {
          if (tx.txIndexMetadata.hasOwnProperty('DESOLockedNanosDiff')) {
              desoLockedNanos += tx.txIndexMetadata.DESOLockedNanosDiff;
          } else if (tx.txIndexMetadata.hasOwnProperty('NFTRoyaltiesMetadata')) {
              desoLockedNanos += tx.txIndexMetadata.NFTRoyaltiesMetadata.CreatorCoinRoyaltyNanos;
          }
      }

      cumulativeDeso += desoLockedNanos / 1e9; // Convert nanos to base unit
      monthlyLockedDeso[monthYear] = Math.max(0, cumulativeDeso); // Prevent negative values
  });

  return Object.entries(monthlyLockedDeso).map(([monthYear, deso]) => ({
      time: monthYear,
      DeSo: Number(deso.toFixed(3)) // Round to 3 decimal places
  }));
};

const CCTransactionLineChart = ({ CCTransactions, NFTBuyNowTransactions, NFTBidTransactions }) => {

    const chartData = useMemo(() => calculateMonthlyLockedDeso(CCTransactions, NFTBuyNowTransactions, NFTBidTransactions), [CCTransactions, NFTBuyNowTransactions, NFTBidTransactions]);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Creator Coin Locked DeSo Value</CardTitle>
                <CardDescription>
                    {/* Description here if needed */}
                </CardDescription>
            </CardHeader>
            <CardContent className="pb-4">
        <div className="h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={chartData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
                  <stop offset="5%" stopColor="#FFFFFF" stopOpacity={0.0} />
                  <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.25} />
                </linearGradient>
              </defs>
              <XAxis dataKey="time" axisLine={false} tickLine={false} />
              <YAxis axisLine={false} tickLine={false} tickFormatter={(value) => value.toFixed(0)} />
              <CartesianGrid horizontal={true} vertical={false} stroke="#20232a" strokeWidth={1} />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#20232a',
                  borderColor: '#20232a'
                }}
                itemStyle={{ color: '#fff' }}
              />
              <Area
                type="monotone"
                dataKey="DeSo"
                stroke="#FFFFFF"
                fillOpacity={1}
                strokeWidth={3}
                fill="url(#colorUv)" // Use the gradient defined in the defs
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

export default CCTransactionLineChart;